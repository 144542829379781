import _superPropBase from "./superPropBase.js";
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
var superPropBase = _superPropBase;
function _get() {
  if (typeof Reflect !== "undefined" && Reflect.get) {
    exports = _get = Reflect.get.bind(), exports.__esModule = true, exports["default"] = exports;
  } else {
    exports = _get = function _get(target, property, receiver) {
      var base = superPropBase(target, property);
      if (!base) return;
      var desc = Object.getOwnPropertyDescriptor(base, property);
      if (desc.get) {
        return desc.get.call(arguments.length < 3 ? target : receiver);
      }
      return desc.value;
    }, exports.__esModule = true, exports["default"] = exports;
  }
  return _get.apply(this || _global, arguments);
}
exports = _get, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;